/*------------------------------------------------------------------
[Fonts] 
*/
@font-face {
    font-family: "Avenir_Black";
    src: local("Avenir_Black"), url('../../../../../../fonts/Avenir\ Black.ttf') format("truetype");
}

@font-face {
    font-family: "Avenir_Book";
    src: local("Avenir_Book"), url('../../../../../../fonts/Avenir\ Book.ttf') format("truetype");
}

@font-face {
    font-family: "Avenir_Heavy";
    src: local("Avenir_Heavy"), url('../../../../../../fonts/Avenir\ Heavy.ttf') format("truetype");
}

@font-face {
    font-family: "Avenir_Regular";
    src: local("Avenir_Regular"), url('../../../../../../fonts/Avenir\ Regular.ttf') format("truetype");
}

@font-face {
    font-family: "Avenir_Light";
    src: local("Avenir_Light"), url('../../../../../../fonts/Avenir\ Light.ttf') format("truetype");
}
