.beneift__button {
    width: 271px !important;
    min-width: 271px !important;
    height: 139px !important;
    max-height: 139px !important;
    background: #fff;
    background-color: #fff !important;
    color: $dodger-blue_6;
    margin-bottom: 24px !important;

    @include breakpoint(desktop) {
        margin: 5px !important;
    }

    @include breakpoint(tablet) {
        width: 271px !important;
        min-width: 271px !important;
        margin: 15px !important;
    }

    @include breakpoint(mobile) {
        margin: 10px !important;
        margin: 10px 0 !important;
    }

    .MuiBottomNavigationAction-wrapper {
        display: flex;
        flex-direction: column;
    }

    .MuiBottomNavigationAction-label {
        font-size: 20px;
        font-family: "Avenir_Black";
        color: $dodger-blue_6;
        text-transform: uppercase;

        @include breakpoint(tablet) {
            font-size: 18px;
            margin-top: 15px;
        }

        @include breakpoint(mobile) {
            font-size: 15px;
        }
    }

    &.Mui-selected {
        background-color: $dodger-blue_6 !important;
        .MuiBottomNavigationAction-label {
            color: #fff;
            .beneift__button-benefitTitle::after {
                border-bottom: 3px solid #fff;
            }
            .beneift__button-benefitSubtitle {
                color: #fff;
            }
        }
    }

    &-benefitTitle {
        &::after {
            content: "";
            width: 96px;
            border-bottom: 3px solid $dodger-blue_6;
            display: block;
            text-align: center;
            margin: 6px auto 0;
        }
    }

    &-benefitSubtitle {
        color: #000;
        margin-top: 10px;
        font-size: 18px;
        text-transform: lowercase;
        font-family: "Avenir_Regular";
    }
}

.beneift__freq {
    position: relative;
    display: flex;
    justify-content: space-between !important;

    @include breakpoint(desktop) {
        flex-wrap: wrap;
        justify-content: center !important;
    }

    @include breakpoint(ipad) {
        justify-content: center !important;
    }

    @include breakpoint(mobile) {
        justify-content: center !important;
    }
}

.options_box,
.date_box {
    margin-top: 80px;
    background-color: #f4f4f4;
    padding: 20px;
    margin-bottom: 20px;
    @include breakpoint(tablet) {
        margin-top: 40px;
    }

    p {
        margin-bottom: 30px;
        font-size: 15px;
    }
}

.options_box {
    .MuiFormControlLabel-label {
        font-family: "Avenir_Regular";
        font-size: 17px;
        min-width: 35px;

        @include breakpoint(mobile) {
            font-size: 15px;
        }
    }
}

.options_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include breakpoint(desktop) {
        justify-content: center;
    }
}

.date_box__container {
    background: #fff;
    display: flex;
    padding: 35px 20px;
    margin-bottom: 70px;
    justify-content: space-around;

    & > div {
        flex: 1;
    }

    @include breakpoint(tablet) {
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    @include breakpoint(mobile) {
        padding: 25px 10px;
    }

    h6 {
        font-size: 17px !important;
        color: #000;

        @include breakpoint(mobile) {
            font-size: 15px !important;
        }
    }

    .MuiStepIcon-root {
        display: inline;
        margin-right: 10px;
    }

    .section__title {
        display: flex;
        align-items: flex-start;
        margin-bottom: 40px;
    }

    .firstdate__container,
    .days__container,
    .hours__container {
        padding: 0 8px;
    }

    .days__container {
        @include breakpoint(tablet) {
            margin-bottom: 30px;
        }

        @include breakpoint(mobile) {
            margin-bottom: 20px;
        }

        .MuiFormGroup-root {
            display: inherit;
            column-count: 3;
            column-gap: 38px;
            column-width: 57px;
            max-width: 165px;
        }

        .MuiFormControlLabel-label {
            font-family: "Avenir_Regular";
            font-size: 17px;
            min-width: 35px;

            @include breakpoint(mobile) {
                font-size: 15px;
            }
        }
    }

    .hours__container {
        label {
            font-family: "Avenir_Black";
            font-size: 17px !important;
            color: $dodger-blue_6 !important;
            text-transform: uppercase;
            margin-bottom: 10px !important;

            @include breakpoint(mobile) {
                font-size: 15px !important;
            }
        }

        .MuiInput-input {
            width: 180px;
            font-size: 15px;
            font-family: "Avenir_Regular";

            @include breakpoint(mobile) {
                width: 145px;
                font-size: 12px;
            }
        }

        .MuiInputBase-formControl {
            margin-bottom: 30px;
        }

        .MuiFormControl-root {
            @include breakpoint(tablet) {
                margin-right: 20px;
            }
        }

        .MuiInputBase-root {
            font-family: "Avenir_Regular";
        }

        .section__title {
            min-width: 277px;

            @include breakpoint(mobile) {
                min-width: 177px;
            }
        }
    }

    .firstdate__container {
        .MuiFormControl-marginNormal {
            margin-top: 0;
            min-width: 250px;

            @include breakpoint(mobile) {
                min-width: 177px;
            }
        }

        label {
            font-family: "Avenir_Black";
            font-size: 17px;
            margin-bottom: 10px !important;
            text-transform: uppercase;

            @include breakpoint(mobile) {
                font-size: 15px;
            }
        }

        .MuiInputBase-input {
            border: none !important;
        }

        .MuiInputBase-root {
            font-family: "Avenir_Regular";
            font-size: 17px;
            padding-left: 20px;

            @include breakpoint(mobile) {
                font-size: 15px;
            }
        }

        .MuiInputBase-formControl {
            border: 2px solid $gray;

            &::before,
            &::after {
                border: none;
                content: none;
            }

            &:focus,
            &:active {
                border: 2px solid $dodger-blue_6;
            }

            &.Mui-error {
                border: 2px solid $coral-red;
            }
        }

        .MuiSvgIcon-root {
            fill: $dodger-blue_6;
        }
    }
    .MuiFormGroup-root {
        flex-direction: unset;
    }
    .MuiFormControlLabel-labelPlacementStart {
        margin-right: 0;
        margin-left: 8px;
        width: 80px;
        display: flex;
        justify-content: space-between;
    }
    .MuiTypography-body1 {
        font-size: 1.8rem;
    }
    .MuiGrid-item {
        width: 100%;
        padding: 12px;
    }
}

.one_shot_date {
    display: flex;
    flex-direction: row !important;
    align-items: baseline;
    flex-wrap: wrap;
    //
    width: 70% !important;
    label {
        margin-right: 20px;
    }

    .MuiInputBase-formControl {
        max-width: 189px;
    }

    #date-picker-inline-helper-text {
        color: $coral-red;
        font-size: 17px;
        font-family: "Avenir_Regular";
    }
    // @include breakpoint(tablet) {
    //     margin-left: 20px !important;
    // }
}

.one_shot_hours {
    .MuiFormControl-root {
        display: flex;
        flex-direction: row !important;
        align-items: baseline;
        flex-wrap: wrap;
        margin: 0px;
        padding: 0 8px;
    }

    label {
        margin-right: 20px;
    }

    .MuiInputBase-formControl {
        max-width: 189px;
    }
}

.react-datepicker__input-container {
    input {
        border: 2px solid $dodger-blue_6;
        padding: 12px 17px;
    }
}

.calendar_wrapper {
    position: relative;
    width: fit-content;

    .MuiSvgIcon-root {
        position: absolute;
        top: 12px;
        right: 80px;
    }
}

.error_morning {
    color: "#ff3e3e";
}

.react-datepicker {
    box-shadow: 0px 3px 6px #00000029;
    border: none !important;
    border-radius: 0;
    font-size: 12px;
    font-size: "Avenir_Regular";
}

.react-datepicker__header {
    background-color: #fff !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    border-radius: 0 !important;
    background-color: $dodger-blue_6 !important;
}

.react-datepicker__navigation-icon::before,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
    border-color: $dodger-blue_6 !important;
    border-width: 1px 1px 0 0 !important;
    height: 25px !important;
}

.react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::before {
    display: none;
}

.react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::after {
    display: none;
}

.react-datepicker__month-container {
    width: 100%;
    height: 100%;
    padding: 10px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    font-size: 14px !important;
    font-family: "Avenir_Black";
    margin-bottom: 10px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    font-size: 15px !important;
    width: 20px !important;
    line-height: 20px !important;
    margin: 3px !important;
}

.react-datepicker__navigation {
    top: 15px !important;
    height: 24px !important;
}

.react-datepicker__navigation-icon {
    width: 100%;
    height: 100%;

    &--next {
        left: -12px !important;
    }
    &::before {
        width: 21px !important;
        top: 2px !important;
    }
}

.react-datepicker__navigation-icon--previous {
    top: -4px !important;
    &::before {
        right: -18px !important;
    }
}
