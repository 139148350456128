.finished_icon {
    max-width: 74px;
    height: auto;
}

.blue_text {
    color: $dodger-blue_6 !important;
}

.thankyoupage {
    h1 {
        font-size: 35px;
        margin-left: 70px;

        @include breakpoint(tablet) {
            margin-top: 79px;
        }

        @include breakpoint(mobile) {
            font-size: 30px;
            margin-left: auto;
        }
    }

    h3 {
        font-size: 24px;

        @include breakpoint(mobile) {
            font-size: 18px;
        }
    }

    .finalsteps {
        display: flex;
        align-items: center;
        margin-bottom: 44px;

        svg {
            margin-right: 30px;
            color: #ffd74f;
            text {
                color: #000 !important;
            }
        }
    }
}
