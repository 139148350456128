.Clexperts {
    background-color: #fff;
    padding: 100px 5%;

    @include breakpoint-reverse(xx-large) {
        padding: 158px 5% 270px;
    }

    @include breakpoint(tablet) {
        padding: 100px 5% 90px;
    }

    @include breakpoint(mobile) {
        padding: 58px 5% 74px;
    }

    h2 {
        margin-bottom: 94px;
        text-align: left;

        @include breakpoint(tablet) {
            margin-bottom: 60px;
            text-align: center;
        }

        @include breakpoint(mobile) {
            margin-bottom: 44px;
        }
    }

    p {
        margin-bottom: 30px;
    }

    span {
        font-family: "Avenir_Black";
    }

    img {
        max-width: 600px;
        width: 100%;
        margin-top: 50px;
        margin-left: 0;

        @include breakpoint-reverse(xx-large) {
            max-width: 711px;
            margin-left: 44px;
        }

        @include breakpoint(tablet) {
            margin: auto;
        }
    }
}
