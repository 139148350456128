.options_card {
    width: 271px;
    height: 100px;
    background: #fff;
    padding: 20px;
    cursor: pointer;
    border: solid 1px #adadad;
    margin-bottom: 10px;

    @include breakpoint(desktop) {
        margin: 5px;
    }

    @include breakpoint(tablet) {
        margin: 15px;
    }

    @include breakpoint(mobile) {
        height: 149px;
        margin: 10px 0px;
    }

    .options_card__header {
        display: block;
        flex-grow: 1;
        //align-items: center;
        margin-bottom: 16px;

        img {
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(45deg)
                brightness(102%) contrast(103%);
            //margin-left: 87px;
            // display: block;
            // margin-right: auto;
            // margin-left: auto;
        }

        .counter {
            margin-left: auto;
            display: flex;
            align-items: center;

            p {
                margin: 0 20px !important;
                font-family: "Avenir_Black";
                font-size: 29px;
                color: $stack;

                @include breakpoint(mobile) {
                    font-size: 20px;
                }
            }

            .counter_btn {
                font-family: "Avenir_Black";
                font-size: 29px;
                color: $dodger-blue_6;

                @include breakpoint(mobile) {
                    font-size: 20px;
                }
            }
        }

        .counter_btn {
            background-color: none;
            border: none;
            background: transparent;
        }
    }

    h6 {
        margin-top: 10px;
        font-size: 12px;
        font-family: "Avenir_Black" !important;
        text-transform: uppercase;
        color: $stack;
        display: flex;
        align-items: center;
        flex-direction: column;

        @include breakpoint(mobile) {
            font-size: 15px;
        }
    }

    .options_card__desc {
        max-width: 200px;
        p {
            font-size: 10px;
            font-family: "Avenir_Regular";
            color: $stack;

            @include breakpoint(mobile) {
                font-size: 12px;
            }
        }
    }

    &.active {
        border: solid 2px #ffd74f;
        box-shadow: 0px 3px 6px #00000029;

        img {
            filter: invert(51%) sepia(83%) saturate(5770%) hue-rotate(175deg)
                brightness(97%) contrast(102%);
            fill: #489dd0;
        }

        .counter {
            p {
                color: #000;
            }
        }

        h6 {
            color: #000;
        }

        .options_card__desc {
            max-width: 250px;
            p {
                color: #000;
            }
        }
    }

    &:nth-child(5) {
        &.active {
            img {
                filter: none !important;
            }
        }
    }
}
.options_card2 {
    width: 271px;
    height: 145px;
    background: #fff;
    padding: 20px;
    cursor: pointer;
    border: solid 1px transparent;
    margin-bottom: 10px;

    @include breakpoint(desktop) {
        margin: 5px;
    }

    @include breakpoint(tablet) {
        margin: 15px;
    }

    @include breakpoint(mobile) {
        height: 149px;
        margin: 10px 0px;
    }

    .options_card__header {
        display: block;
        flex-grow: 1;
        //align-items: center;
        margin-bottom: 16px;

        img {
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(45deg)
                brightness(102%) contrast(103%);
            //margin-left: 87px;
        }

        .counter {
            margin-left: auto;
            display: flex;
            align-items: center;

            p {
                margin: 0 20px !important;
                font-family: "Avenir_Black";
                font-size: 29px;
                color: $stack;
                display: flex;
                align-items: center;
                flex-direction: column;
                @include breakpoint(mobile) {
                    font-size: 20px;
                }
            }

            .counter_btn {
                font-family: "Avenir_Black";
                font-size: 29px;
                color: $dodger-blue_6;

                @include breakpoint(mobile) {
                    font-size: 20px;
                }
            }
        }

        .counter_btn {
            background-color: none;
            border: none;
            background: transparent;
        }
    }

    h6 {
        margin-top: 10px;
        font-size: 12px;
        font-family: "Avenir_Black" !important;
        text-transform: uppercase;
        color: $stack;
        display: flex;
        align-items: center;
        flex-direction: column;
        @include breakpoint(mobile) {
            font-size: 15px;
        }
    }

    .options_card__desc {
        p {
            font-size: 13px;
            font-family: "Avenir_Regular";
            color: $stack;
            display: flex;
            align-items: center;
            flex-direction: column;
            @include breakpoint(mobile) {
                font-size: 12px;
            }
        }
    }

    &.active {
        border: solid 2px #ffd74f;
        box-shadow: 0px 3px 6px #00000029;

        img {
            filter: invert(51%) sepia(83%) saturate(5770%) hue-rotate(175deg)
                brightness(97%) contrast(102%);
            fill: #489dd0;
        }

        .counter {
            p {
                color: #000;
            }
        }

        h6 {
            color: #000;
        }

        .options_card__desc {
            max-width: 250px;
            p {
                color: #000;
            }
        }
    }

    &:nth-child(5) {
        &.active {
            img {
                filter: none !important;
            }
        }
    }
}
.options_card2_disabled {
    width: 271px;
    height: 145px;
    background: #f2eded;
    padding: 20px;
    cursor: pointer;
    border: solid 1px transparent;
    margin-bottom: 10px;

    @include breakpoint(desktop) {
        margin: 5px;
    }

    @include breakpoint(tablet) {
        margin: 15px;
    }

    @include breakpoint(mobile) {
        height: 149px;
        margin: 10px 0px;
    }

    .options_card__header {
        display: block;
        flex-grow: 1;
        //align-items: center;
        margin-bottom: 16px;

        img {
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(45deg)
                brightness(102%) contrast(103%);
            //margin-left: 87px;
        }

        .counter {
            margin-left: auto;
            display: flex;
            align-items: center;

            p {
                margin: 0 20px !important;
                font-family: "Avenir_Black";
                font-size: 29px;
                color: $stack;
                display: flex;
                align-items: center;
                flex-direction: column;

                @include breakpoint(mobile) {
                    font-size: 20px;
                }
            }

            .counter_btn {
                font-family: "Avenir_Black";
                font-size: 29px;
                color: $dodger-blue_6;

                @include breakpoint(mobile) {
                    font-size: 20px;
                }
            }
        }

        .counter_btn {
            background-color: none;
            border: none;
            background: transparent;
        }
    }

    h6 {
        margin-top: 10px;
        font-size: 12px;
        font-family: "Avenir_Black" !important;
        text-transform: uppercase;
        color: $stack;
        display: flex;
        align-items: center;
        flex-direction: column;
        @include breakpoint(mobile) {
            font-size: 15px;
        }
    }

    .options_card__desc {
        p {
            font-size: 13px;
            font-family: "Avenir_Regular";
            color: $stack;
            display: flex;
            align-items: center;
            flex-direction: column;
            @include breakpoint(mobile) {
                font-size: 12px;
            }
        }
    }
}
