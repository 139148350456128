.StripeElement,
.StripeElementIdeal,
.StripeElementP24,
.StripeElementEPS,
.StripeElementFpx {
  display: block;
  margin: 0px 0 16px 0;
  /* min-width: 300px; */
  padding: 10px 14px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 0px;
  background: white;
  border: 2px solid #2880f9 !important;
  /* padding: 18.5px 14px, */
  /* border-radius: 0px */
}

.StripeElementIdeal,
.StripeElementP24,
.StripeElementEPS,
.StripeElementFpx {
  padding: 0;
}
