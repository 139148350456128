.sidebar {
    margin-top: 157px;
    max-width: 350px;
    margin-left: auto;
    position: sticky;
    position: -webkit-sticky;
    top: 86px;
    margin-right: 20px;

    @include breakpoint(desktop) {
        margin-top: 110px;
    }

    @include breakpoint(tablet) {
        margin-top: 0;
        margin-right: auto;
    }

    h2 {
        font-size: 24px !important;

        @include breakpoint(mobile) {
            font-size: 20px !important;
        }
    }

    &__submit {
        color: #fff !important;
        background-color: $shamrock !important;
        box-shadow: none !important;
        padding: 27px 100px !important;
        width: 100%;
        border-radius: 0 !important;
        font-family: "Avenir_Black" !important;
        margin-top: 20px !important;

        @include breakpoint(mobile) {
            padding: 14px 63px !important;
        }
    }

    &__btnInformation {
        color: #fff !important;
        background-color: $shamrock !important;
        box-shadow: none !important;
        padding: 20px 0px !important;
        width: 100%;
        border-radius: 0 !important;
        margin: 30px 0 !important;
        @include breakpoint(mobile) {
            padding: 14px 63px !important;
        }

        & p {
            font-family: "Avenir_Black" !important;
            font-size: 16.5px !important;
            margin: 0;
            padding: 0;
        }
    }
}

.demande__housing_specification {
    border-bottom: 1px solid $gray;
    min-height: 200px;
    padding: 49px 10px 0 10px;

    h2::after {
        content: "";
        border-bottom: 5px solid $mountain-meadow;
        width: 141px;
        display: block;
        margin-top: 18px;
    }

    h4 {
        margin-top: 30px;
    }
}

.demande__balance {
    border-bottom: 1px solid $gray;
    padding: 12px 10px 0 10px;

    .balance__info {
        font-size: 16px;
        text-decoration: underline;
        color: $dodger-blue_7;
        text-transform: inherit;
        font-family: "Avenir_Regular";
        padding: 6px 0;
        &:hover {
            background: transparent;
        }

        &:focus,
        &:active {
            outline: none;
            border: 0px solid $shamrock;
        }

        & ~ p {
            color: #717171;
            font-size: 14px;
            text-align: left;
        }

        & p {
            margin: 0 !important;
        }

        & .MuiTouchRipple-root {
            background-color: #fff !important;
            opacity: 0.3 !important;
        }
    }
}

.balance__title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
        font-size: 28px;

        @include breakpoint(desktop) {
            font-size: 26px;
        }

        @include breakpoint(mobile) {
            font-size: 20px;
        }

        span {
            color: $dodger-blue_6;
        }
    }
}

.sidebar_inner {
    background: #fff;
}
