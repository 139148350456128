.MuiStep-horizontal {
    position: relative;

    &:not(:last-child) {
        padding-right: 91px !important;

        @include breakpoint-reverse(xx-large) {
            padding-right: 100px !important;
        }

        @include breakpoint(tablet) {
            padding-right: 20px !important;
        }

        &::after {
            content: "";
            width: 111px;
            height: 100%;
            position: absolute;
            top: -51px;
            left: 50%;
            background-image: linear-gradient(
                to right,
                $dove-gray_1 40%,
                rgba(255, 255, 255, 0) 20%
            );
            background-position: bottom;
            background-size: 19px 2px;
            background-repeat: repeat-X;

            @include breakpoint-reverse(xx-large) {
                width: 133px;
                background-size: 27px 2px;
            }

            @include breakpoint(tablet) {
                width: 59px;
                left: 65%;
                top: -41px;
                background-size: 10px 2px;
            }
        }

        &.MuiStep-completed {
            &::after {
                content: "";
                width: 123px;
                position: absolute;
                border-bottom: 3px solid #707070;
                top: -51px;
                left: 50%;
                background-image: none;

                @include breakpoint-reverse(xx-large) {
                    width: 133px;
                    border-bottom: 5px solid $dodger-blue_6;
                }

                @include breakpoint(tablet) {
                    width: 59px;
                    left: 69%;
                    top: -40px;
                }
            }
        }
    }

    .MuiStepLabel-horizontal {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .MuiStepLabel-iconContainer {
            margin-bottom: 10px;
        }
    }

    .MuiStepLabel-completed {
        color: $dodger-blue_6 !important;
        font-family: "Avenir_Black";
    }

    .MuiStepIcon-root {
        color: transparent;
        border: 1px solid $shamrock;
        border-radius: 50%;
        width: 35px;
        height: 35px;

        @include breakpoint(tablet) {
            width: 20px;
            height: 20px;
        }
    }
    .MuiStepLabel-iconContainer {
        & .MuiStepIcon-completed {
            color: $shamrock;
        }
    }

    .MuiTypography-body2 {
        @include breakpoint(tablet) {
            font-size: 15px;
        }
    }

    .MuiStepIcon-text {
        fill: #000;
        font-family: "Avenir_Heavy";
    }

    .MuiTypography-body2 {
        font-family: "Avenir_Book";
        color: $dove-gray_3;
    }

    & .MuiStepIcon-active,
    & .MuiStepIcon-completed {
        border: none !important;

        .MuiStepIcon-text {
            fill: #fff;
        }
    }

    .MuiStepLabel-label {
        text-transform: uppercase;
    }

    .MuiStepLabel-label.MuiStepLabel-completed {
        font-family: "Avenir_Black";
        color: #000 !important;
    }

    .MuiStepLabel-label.MuiStepLabel-active {
        font-family: "Avenir_Black";
        color: $dove-gray_3;
    }

    .MuiStepLabel-labelContainer {
        min-width: 110px;
        margin: auto;
        text-align: center;

        @include breakpoint(tablet) {
            min-width: 93px;
        }
    }
}
