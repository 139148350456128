.Validation {
    margin: 46px auto;
    padding: 0 50px;
    max-width: 1500px;

    @include breakpoint(mobile) {
        margin: 34px 0;
        padding: 0px 16px;
    }

    .row {
        margin-right: 0;
        margin-left: 0;
    }

    .Validation_row {
        margin-top: 30px;
        margin-bottom: 60px;
        align-items: flex-start;
        justify-content: space-between;
    }

    .information_box {
        max-width: 600px;
        //margin: auto;
    }

    .Validation_col {
        background-color: #fff;
        padding: 25px 15px;
        width: 97%;
        margin: 10px auto;
        height: auto;
        @include breakpoint(tablet) {
            width: 100%;
        }
        @include breakpoint(mobile) {
            width: 100%;
        }
    }

    .Validation_TwiceRequest_col {
        background-color: #fff;
        padding: 25px 15px;
        width: 50%;
        margin: 15px auto;

        @include breakpoint(desktop) {
            width: 75%;
        }

        @include breakpoint(mobile) {
            width: 100%;
        }
    }

    .section__title {
        display: flex;
        align-items: center;
        h6 {
            margin-left: 10px;
        }
    }

    h6 {
        color: #0d0e12;
        font-family: "Avenir_Black" !important;
        font-size: 17px;
        text-transform: uppercase;
        @include breakpoint(mobile) {
            font-size: 14px;
        }
    }

    .blue__title {
        color: $shamrock;
        font-family: "Avenir_Black";
        text-transform: uppercase;
        font-size: 15px;
        @include breakpoint(mobile) {
            font-size: 14px;
        }
    }

    .validation_recap {
        margin-top: 40px;
        margin-left: 10px;
        @include breakpoint(desktop) {
            //margin-left: 40px;
        }
        .blue__title {
            font-size: 17px;

            @include breakpoint(mobile) {
                font-size: 14px;
            }
        }
    }

    .recap_title {
        font-size: 20px;
        margin: 20px auto, 0, auto;
        text-transform: uppercase;

        @include breakpoint(mobile) {
            font-size: 16px;
        }
    }

    .MuiFormControlLabel-root {
        align-items: flex-start;
    }

    .MuiFormControlLabel-label {
        font-family: "Avenir_Regular";
        font-size: 17px;
        color: #000;

        @include breakpoint(mobile) {
            font-size: 14px;
        }

        a {
            text-decoration: underline;
        }
    }

    .error {
        .MuiCheckbox-root {
            color: $coral-red;
        }
    }

    .MuiCheckbox-root {
        padding: 0 9px !important;
    }

    .terms_link {
        font-family: "Avenir_Regular";
        font-size: 17px;
        color: #000;

        @include breakpoint(mobile) {
            font-size: 14px;
        }
    }

    .validation__submit {
        color: #fff !important;
        //background-color: $dove-gray_1 !important;
        background-color: $dodger-blue_5 !important;
        box-shadow: none !important;
        padding: 20px !important;
        width: 100%;
        border-radius: 0 !important;
        font-family: "Avenir_Black";
        font-size: 16.5px;
        margin: 30px 0;
        @include breakpoint(desktop) {
            padding: 19px 15px !important;
            font-size: 14px;
        }

        @include breakpoint(mobile) {
            padding: 19px 15px !important;
            font-size: 14px;
        }
    }

    .validation__submit_disabled {
        background-color: $dove-gray_1 !important;
    }

    .information_form {
        display: flex;
        flex-wrap: wrap;
        margin-top: 47px;
        justify-content: space-around;

        .MuiFormControl-root {
            margin-bottom: 60px;
            @include breakpoint(mobile) {
                max-width: 276px;
                width: 276px;
            }
            & legend {
                max-width: 0px;
            }
        }
    }

    .MuiInputLabel-formControl {
        transform: translate(0, 0) !important;
        position: absolute !important;
        margin: 16px;
    }

    .MuiFormControl-root {
        height: 40px;
        max-width: 220px;

        @include breakpoint(mobile) {
            width: 100%;
        }
    }

    .MuiFormLabel-root {
        font-family: "Avenir_Regular";
        font-size: 15px;
        color: $dodger-blue_6 !important;

        @include breakpoint(mobile) {
            font-size: 12px;
        }
    }

    .MuiOutlinedInput-input {
        padding: 30px 13px 12px 14px;
        font-family: "Avenir_Regular";
        font-size: 17px;
        border: 2px solid #2880fb !important;

        @include breakpoint(mobile) {
            font-size: 14px;
        }
    }

    .MuiInput-underline:after,
    .MuiInput-underline:before {
        display: none;
    }

    .password_container {
        position: relative;
    }

    .visibility_button {
        position: absolute;
        right: 0;
        top: 70%;
    }

    .MuiOutlinedInput-adornedEnd {
        padding-right: 0;
    }

    .visibilityOff_icon {
        fill: $dodger-blue_6;
    }

    .progress {
        width: 246px;

        @include breakpoint(mobile) {
            width: auto;
        }
    }

    .progress_bar {
        width: 100%;
        margin-left: 6%;

        @include breakpoint(tablet) {
            margin-left: 91px;
        }

        @include breakpoint(mobile) {
            margin-left: 0;
        }
    }

    .password_strength {
        font-family: "Avenir_Heavy";
        color: #1d1e21;
        font-size: 15px;

        @include breakpoint(mobile) {
            font-size: 12px;
        }
    }

    .password_strength_message {
        color: #a5a4a6;
        font-size: 14px;
        margin-top: 1rem;

        @include breakpoint(mobile) {
            font-size: 11px;
        }
    }

    .estimation_box {
        // border-top: solid 2px $dove-gray_2;
        margin-left: 10px;
        & h4 {
            font-size: 23px;
        }
        & p {
            font-size: 23px;
        }
        @include breakpoint(desktop) {
            & h4 {
                font-size: 21px;
            }
            & p {
                font-size: 21px;
            }
        }
    }

    .estimation_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 15px;
    }

    .estimation_title,
    .estimation_price {
        font-family: "Avenir_Black";
        text-transform: uppercase;
        font-size: 25px;

        @include breakpoint(mobile) {
            font-size: 17px;
        }
    }

    .estimation_price {
        color: $dodger-blue_6;
    }

    .MuiLinearProgress-colorPrimary {
        background-color: #aaaaaa;

        &[aria-valuenow="20"] {
            .MuiLinearProgress-barColorPrimary {
                background-color: $coral-red;
            }
        }

        &[aria-valuenow="40"],
        &[aria-valuenow="60"] {
            .MuiLinearProgress-barColorPrimary {
                background-color: #f79c09;
            }
        }

        &[aria-valuenow="80"],
        &[aria-valuenow="100"] {
            .MuiLinearProgress-barColorPrimary {
                background-color: $shamrock;
            }
        }
    }

    .react-tel-input {
        box-shadow: none !important;
        border-radius: 0 !important;
        font-family: "Avenir_Regular";
        font-size: 17px;
        position: relative;
        max-width: 220px;
        margin-bottom: 27px;

        @include breakpoint(mobile) {
            max-width: 274px;
            width: 274px;
        }

        .form-control {
            font-size: 17px;
            box-shadow: 0 0 0 0.2rem #2880f9;
            height: 74px !important;
            border-radius: 0;
        }

        .flag-dropdown {
            border-radius: 0;
        }

        .selected-flag {
            border-radius: 0;
        }
    }
}

.recap_list {
    color: $dove-gray_2;
    font-size: 17px;

    @include breakpoint(mobile) {
        font-size: 14px;
    }
}

.error_box {
    margin-top: 47px;
    width: 100%;
    background-color: #fff;
    padding: 20px 30px;
    display: flex;
    gap: 20px;
    align-items: center;
    border: 1px solid $coral-red;
}

.error_icon {
    max-width: 30px;
    height: auto;
}

.error_message {
    color: $coral-red;
    text-align: center;
}

input:-internal-autofill-selected {
    border-radius: 0 !important;
}
