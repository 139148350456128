/*------------------------------------------------------------------
[Buttons] 
*/

.btn {
    font-family: "Avenir_Black";
    font-size: 15px;
    padding: 10px 28px;
    border-radius: 0 !important;
    text-transform: uppercase;
    transition: ease-in 0.3s;
    border: none;

    @include breakpoint(mobile) {
        font-size: 15px;
    }

    &:hover,
    &:focus {
        text-decoration: none;
        outline: none;
        border: $shamrock;
        box-shadow: $shamrock;
    }
}

.btn_blue_bg {
    @extend .btn;
    background: $shamrock;
    border: 3px solid $shamrock;
    color: rgb(10, 10, 10);
    &:focus {
        outline: none;
    }

    &:hover {
        color: $shamrock;
        background: #fff;
        border: $shamrock;
    }
}

.btn_green_bg {
    @extend .btn;
    background: $bg_green !important;
    color: #000 !important;
    transition: ease-in 0.3s;
    border: 3px solid $bg_green !important;
    width: 346px;
    height: 62px;
    margin-top: 20px;
    margin-left: 130px;
    &:hover {
        color: $bg_green !important;
        background: #fff !important;
    }
    &:focus {
        outline: none;
        border: $shamrock;
    }
    @include breakpoint(mobile) {
        background: $bg_green !important;
        color: #000 !important;
        transition: ease-in 0.3s;
        border: 3px solid $bg_green !important;
        width: 80%;
        height: 62px;
        margin-top: 20px;
        margin-left: 0px;
        &:hover {
            color: $bg_green !important;
            background: #fff !important;
        }
        &:focus {
            outline: none;
            border: $shamrock;
        }
    }
    @include breakpoint(tablet) {
        background: $bg_green !important;
        color: #000 !important;
        transition: ease-in 0.3s;
        border: 3px solid $bg_green !important;
        width: 450px;
        height: 62px;
        margin-top: 20px;
        margin-left: 0px;

        &:hover {
            color: $bg_green !important;
            background: #fff !important;
        }
        &:focus {
            outline: none;
            border: $shamrock;
        }
    }
}

.btn_nav {
    @extend .btn;
    background: #fff;
    color: $btn_nav_blue;
    border: 3px solid #fff;

    &:hover {
        color: #fff;
        background: #443ffd;
        border: 3px solid #fff;
    }
    &:focus {
        outline: none;
        border: $shamrock;
    }
}

.btn_round {
    font-family: "Avenir_Black";
    font-size: 15px;
    padding: 10px 28px;
    border-radius: 13px;
    text-transform: uppercase;
    transition: ease-in 0.3s;
    border: 3px solid $dodger-blue_4;
    color: $dodger-blue_4;

    &:hover,
    &:focus {
        text-decoration: none;
        color: #fff;
        background: $dodger-blue_4;
    }

    @include breakpoint(mobile) {
        font-size: 11px;
        padding: 10px 18px;
    }
}

.MuiButton-label {
    font-family: "Avenir_Black";
}

.see_more_btn {
    padding: 0 !important;

    &:hover {
        background-color: #fff !important;
    }

    .see_more_text {
        font-size: 16px;
        text-decoration: underline;
        color: #2a81f9;
        font-family: "Avenir_Regular";
        text-transform: initial;
    }
}
