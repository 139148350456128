* {
    margin: 0;
    padding: 0;
}

html {
    font-size: 62.5%;
    background-color: #eaeaea;
}

body {
    margin: 0;
    box-sizing: border-box;
    background-color: #eaeaea;
    font-family: "Roboto", "Avenir", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* background-color: aqua; */
}

h1 {
    font-family: Righteous;
}

h2 {
    font-family: Righteous;
}

p,
h3,
h4,
h5,
h6 {
    font-family: sans-serif;
}

.App {
    font-size: 1.6rem;
}

.container {
    padding: 0 3rem;
}

p {
    font-size: 1.6rem;
}

/* 
@media (max-width: 768px) {
  .container {
    max-width: 100%;
  }
}
 */

@media (max-width: 768px) {
    .container {
        margin: 220px 0px 150px 10px;
    }

    h2 {
        font-size: 3rem;
    }

    h3 {
        font-size: 2.1rem;
    }

    p {
        font-size: 2rem;
    }
}

@media (min-width: 1600px) {
    /*   
  .container {
    max-width: 1530px;
  }
 */
    h1 {
        font-size: 4.5rem;
    }

    h2 {
        font-size: 3.5rem;
    }

    h3 {
        font-size: 3rem;
    }

    h4 {
        font-size: 2.5rem;
    }

    h5 {
        font-size: 2.1rem;
    }

    h6 {
        font-size: 1.5rem;
    }
}

.leaflet-container {
    width: 100%;
    height: 25vh;
    position: relative;
    z-index: 1;
    border-radius: 28px;
}
