/*------------------------------------------------------------------
[Media queries] 
*/
@mixin breakpoint($point){
    @if $point == xx-large {
      @media only screen and (max-width:1599px) {
        @content;
      }
    } @else if $point == desktop {
      @media only screen and (max-width:1279px) {
        @content;
      }
    } @else if $point == tablet {
      @media only screen and (max-width: 960px) {
        @content;
      }
    } @else if $point == ipad {
      @media only screen and (max-width: 768px) {
        @content;
      }
    } @else if $point == mobile {
      @media only screen and (max-width: 599px) {
        @content;
      }
    } @else if $point == mobile-s {
      @media only screen and (max-width: 330px) {
        @content;
      }
    } 
  }

  
@mixin breakpoint-reverse($point){
  @if $point == xx-large {
    @media only screen and (min-width: 1600px) {
      @content;
    }
  } @else if $point == desktop {
    @media only screen and (min-width: 1280px) {
      @content;
    }
  } @else if $point == tablet {
    @media only screen and (min-width: 1025px) {
      @content;
    }
  } @else if $point == mobile {
    @media only screen and (min-width: 600px) {
      @content;
    }
  } 
}