/*------------------------------------------------------------------
[Footer : Client homepage] 
*/
.Footer {
    color: #fff;
    font-family: "Avenir_Regular";
    font-size: 18px;
    padding: 0 2% 0 5%;
    box-shadow: 0px -25px 15px #00000029;
    width: 100%;

    @include breakpoint(mobile) {
        font-size: 12px;
        padding: 0 2% 20px 5%;
    }

    .logo_box {
        @include breakpoint(mobile) {
            align-items: center;
            margin-bottom: 42px;
        }
    }

    &_logo {
        max-width: 228px;
        margin-left: 78px;

        @include breakpoint(tablet) {
            margin-left: 0;
            max-width: 128px;
        }
    }

    &__link-Box {
        min-width: 220px;

        @include breakpoint(tablet) {
            min-width: 150px;
            margin: 0 23px 20px !important;
        }

        @include breakpoint(mobile) {
            text-align: center;
            margin: 20px 0 !important;
        }

        ul {
            @include breakpoint(mobile) {
                margin-bottom: 42px;
            }
        }
    }

    &__link a {
        font-size: 18px;
        color: #fff;
        font-family: "Avenir_Regular";
        transition: ease-in 0.3s;

        @include breakpoint(mobile) {
            font-size: 15px;
        }

        &:hover {
            font-family: "Avenir_Black";
            text-decoration: none;
        }
    }

    &__social-links {
        @include breakpoint(mobile) {
            text-align: center;
            margin-bottom: 20px;
        }
    }

    .iconFacebook {
        width: 1.1rem;

        @include breakpoint(mobile) {
            width: 1rem;
        }
    }

    .iconLinkedin {
        width: 2.4rem;

        @include breakpoint(tablet) {
            width: 2rem;
        }
    }

    .iconInstagram {
        width: 2.6rem;

        @include breakpoint(tablet) {
            width: 2.1rem;
        }
    }

    .desktop_copyright {
        @include breakpoint(tablet) {
            display: none;
        }
    }
}

/*------------------------------------------------------------------
[Footer : Choose service] 
*/
.FooterServ {
    color: #fff;
    font-family: "Avenir_Regular";
    font-size: 18px;
    border-top: 2px solid #d6d6d6;
    width: 100%;

    &_info {
        color: $gray;
        background: #fff;
        padding: 12px;

        .row {
            align-items: center;
        }

        p {
            font-family: "Avenir_Black";
            font-size: 17px;

            @include breakpoint(mobile) {
                font-size: 14px;
            }
        }

        svg {
            max-width: 30px;

            .a {
                fill: $gray;
                stroke: $gray;
            }

            .b {
                fill: $gray;
                stroke: #fff;
                stroke-width: 2px;
            }
        }

        &__row {
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 1300px;

            p {
                margin-bottom: 0;
                margin-left: 20px;
            }

            @include breakpoint(tablet) {
                justify-content: left;
                max-width: 300px;
                margin: auto;
            }
        }
    }

    &_main {
        background-color: #489dd0;
        padding: 0 5%;

        @include breakpoint(mobile) {
            padding: 24px 5%;
        }
    }

    &__box {
        max-width: 1200px;
        margin: auto;
    }

    .Footer_logo {
        margin-left: 0;
        max-width: 228px;
        width: 160px;

        @include breakpoint(mobile) {
            margin: auto;
        }
    }

    ul {
        @include breakpoint(mobile) {
            justify-content: center;
        }
    }
}

.Footer_pay-icons {
    display: flex;
    align-items: center;

    @include breakpoint(mobile) {
        justify-content: center;
    }

    span {
        background: #fff;
        width: 55px;
        height: 35px;
        border-radius: 10px;
        padding: 10px 14px;
        display: grid;
        place-content: center;

        &:not(:last-child) {
            margin-right: 17px;
        }

        img {
            max-width: 40px;
            max-height: 35px;
            width: 100%;
        }
    }
}
