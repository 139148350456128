/*------------------------------------------------------------------
[Colors] 
*/
$azure-blue: #489dd0;
$dodger-blue_1: #489dd0;
$dodger-blue_2: #489dd0;
$dodger-blue_3: #489dd0;
$dodger-blue_4: #489dd0;
$dodger-blue_5: #489dd0;
$dodger-blue_6: #489dd0;
$dodger-blue_7: #489dd0;
$gun-powder: #3f3d56;
$shamrock: #ffd74f;
$mountain-meadow: #ffd74f;
$red: #ff1212;
$coral-red: #ff3e3e;
$red-orange: #ff3535;
$gallery: #ebebeb;
$silver-chalice: #b2b1b1;
$gray: #939292;
$gray_2: #868686;
$stack: #8e8f8d;
$monsoon: #8d898d;
$mountain-mist: #a5a4a6;
$dusty-gray: #969595;
$dove-gray_1: #707070;
$dove-gray_2: #6a6a6a;
$dove-gray_3: #666666;
$scorpion: #575757;
$cod-gray: #1a1a1a;
$cinder: #0a0b0f;
$woodsmoke: #090a0c;

/*------------------------------------------------------------------
[Components] 
*/
$body_bg_color: $gallery;
$body_text_color: $cod-gray;

// Titles
$title_color: $cinder;

// buttons
$bg_green: $shamrock;
$btn_bg_blue: $shamrock;
$btn_nav_blue: $dodger-blue_3;
